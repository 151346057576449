export function autoFormat(
  value: Date | number | string,
  formatType: "auto" | "date" | "full" | "time" = "full",
): string {
  const dayjs = useDayjs();
  const datetime =
    typeof value === "number" ? dayjs(value * 1000) : dayjs(value);
  let format: string;
  if (formatType === "date" || (formatType === "auto" && !datetime.isToday())) {
    format = "YYYY-MM-DD";
  } else if (
    formatType === "time" ||
    (formatType === "auto" && datetime.isToday)
  ) {
    format = "HH:mm:ss";
  } else {
    format = "YYYY-MM-DD HH:mm:ss";
  }
  return datetime.format(format);
}
