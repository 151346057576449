import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "#ef4452" }, [
      _createElementVNode("path", { d: "M26.446 24.815a2.5 2.5 0 0 1-2.5-2.5c0-3.907 2.356-5.798 4.076-7.178 1.343-1.078 2.117-1.747 2.394-2.96.225-.984.055-1.812-.52-2.533-.746-.936-2.132-1.564-3.45-1.564a3.89 3.89 0 0 0-3.729 2.764c-.119.381-.179.78-.179 1.185a2.5 2.5 0 1 1-5 0c0-.909.136-1.806.403-2.668 1.167-3.756 4.584-6.28 8.504-6.28 2.844 0 5.663 1.321 7.359 3.446 1.519 1.904 2.046 4.306 1.486 6.763-.678 2.971-2.597 4.511-4.14 5.748-1.568 1.259-2.205 1.828-2.205 3.278a2.5 2.5 0 0 1-2.5 2.5ZM26.478 27.472c-1.971 0-3.569 1.608-3.569 3.592s1.598 3.592 3.569 3.592 3.569-1.608 3.569-3.592-1.598-3.592-3.569-3.592M10.558 27.472c-1.971 0-3.569 1.608-3.569 3.592s1.598 3.592 3.569 3.592 3.569-1.608 3.569-3.592-1.598-3.592-3.569-3.592M10.559 23.558a2.5 2.5 0 0 1-2.5-2.5V5.936a2.5 2.5 0 1 1 5 0v15.122a2.5 2.5 0 0 1-2.5 2.5" })
    ], -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M0 0h40v40H0z"
    }, null, -1)
  ])))
}
export default { render: render }