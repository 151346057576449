import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" d=\"M0 0h40v40H0z\"></path><path fill=\"#6b7683\" d=\"M28.459 17.793H11.527v-6.708c0-4.668 3.798-8.466 8.466-8.466s8.466 3.798 8.466 8.466zm-13.932-3h10.932v-3.708c0-3.014-2.452-5.466-5.466-5.466s-5.466 2.452-5.466 5.466z\"></path><rect width=\"30\" height=\"23\" x=\"4.993\" y=\"13.085\" fill=\"#afb7c0\" rx=\"4\" ry=\"4\"></rect><g fill=\"#313d4c\"><circle cx=\"19.993\" cy=\"22.127\" r=\"3\"></circle><path d=\"M19.993 29.877a1.5 1.5 0 0 1-1.5-1.5V22.96a1.5 1.5 0 1 1 3 0v5.417a1.5 1.5 0 0 1-1.5 1.5\"></path></g>", 4)
  ])))
}
export default { render: render }