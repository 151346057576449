import payload_plugin_ooOTSjEDPF from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.4.3_vue@3.5.13_typescript@5.4.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_fdWyDADvsb from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g8Ipgq9X1C from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_leUBCqjXyM from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UvwrKigNnb from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KOTwY2vM05 from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NgOXilNvRj from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rwtMPzu5jF from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DZ8gSksT7B from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.4.3_vue@3.5.13_typescript@5.4.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_8dMC55yZmH from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.11.19_db0@0.2.1_encoding@0.1.13_eslint@8.57._5et3xnj7crvepe7qhom2w4tq3q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WMkoX6aoj7 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.29.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_flrJMJ9PfT from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_0vb6LR5CYa from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.4_27rplyucjwntvtashyb3xi6jom/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import server_api_xr0Ky6u54N from "/app/apps/web/src/plugins/server-api.ts";
import pagination_client_mzaElNr7rV from "/app/apps/web/src/plugins/pagination.client.ts";
import account_BmOH349oZJ from "/app/apps/web/src/plugins/account.ts";
import sentry_client_KAXFuL2wum from "/app/apps/web/src/plugins/sentry.client.ts";
import vue3_toastify_client_sNkMxBXdsV from "/app/apps/web/src/plugins/vue3-toastify.client.ts";
import plugin_auto_pageviews_client_8hlx0UFexv from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_ooOTSjEDPF,
  revive_payload_client_fdWyDADvsb,
  unhead_g8Ipgq9X1C,
  router_leUBCqjXyM,
  payload_client_UvwrKigNnb,
  navigation_repaint_client_KOTwY2vM05,
  check_outdated_build_client_NgOXilNvRj,
  chunk_reload_client_rwtMPzu5jF,
  plugin_vue3_DZ8gSksT7B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8dMC55yZmH,
  plugin_WMkoX6aoj7,
  plugin_client_flrJMJ9PfT,
  plugin_0vb6LR5CYa,
  server_api_xr0Ky6u54N,
  pagination_client_mzaElNr7rV,
  account_BmOH349oZJ,
  sentry_client_KAXFuL2wum,
  vue3_toastify_client_sNkMxBXdsV,
  plugin_auto_pageviews_client_8hlx0UFexv
]