export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"content":"/logo/open-graph.jpg","property":"og:image"},{"content":"website","property":"og:type"},{"content":"ERA1980","property":"og:site_name"},{"content":"#ffffff","name":"msapplication-TileColor"},{"content":"/icon/browserconfig.xml","name":"msapplication-config"},{"content":"#ffffff","name":"theme-color"}],"link":[{"href":"/icon/apple-touch-icon.png","rel":"apple-touch-icon","sizes":"180x180"},{"href":"/icon/apple-touch-icon-152x152.png","rel":"apple-touch-icon","sizes":"152x152"},{"href":"/icon/apple-touch-icon-144x144.png","rel":"apple-touch-icon","sizes":"144x144"},{"href":"/icon/apple-touch-icon-120x120.png","rel":"apple-touch-icon","sizes":"120x120"},{"href":"/icon/apple-touch-icon-114x114.png","rel":"apple-touch-icon","sizes":"114x114"},{"href":"/icon/apple-touch-icon-76x76.png","rel":"apple-touch-icon","sizes":"76x76"},{"href":"/icon/apple-touch-icon-72x72.png","rel":"apple-touch-icon","sizes":"72x72"},{"href":"/icon/apple-touch-icon-60x60.png","rel":"apple-touch-icon","sizes":"60x60"},{"href":"/icon/apple-touch-icon-57x57.png","rel":"apple-touch-icon","sizes":"57x57"},{"href":"/icon/favicon.ico","rel":"shortcut icon"},{"href":"/icon/favicon-32x32.png","rel":"icon","sizes":"32x32","type":"image/png"},{"href":"/icon/favicon-16x16.png","rel":"icon","sizes":"16x16","type":"image/png"},{"href":"/icon/site.webmanifest","rel":"manifest"}],"style":[],"script":[{"src":"https://unpkg.com/element-internals-polyfill@latest/dist/index.js","type":"module"}],"noscript":[],"htmlAttrs":{"lang":"ko"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false