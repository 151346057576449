import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      d: "M0 0h40v40H0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#ef4452",
      d: "M11.47 27.346H4.975A1.975 1.975 0 0 1 3 25.371V14.629c0-1.091.884-1.975 1.975-1.975h6.495z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#f56570",
      d: "M11.47 27.346V12.654l18.474-8.76a1.975 1.975 0 0 1 2.821 1.785v28.643a1.975 1.975 0 0 1-2.821 1.785l-18.474-8.76Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#e32939",
      d: "M32.765 16.327h2.26c1.091 0 1.975.884 1.975 1.975v3.396a1.975 1.975 0 0 1-1.975 1.975h-2.26"
    }, null, -1)
  ])))
}
export default { render: render }