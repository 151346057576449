<script setup lang="ts">
import { GetStateCommand } from "@internal/server-api";
import RiPhoneLine from "vue-remix-icons/icons/ri-phone-line.vue";
export interface ContactInfo {
  [key: string]: string;
  presidentName: string;
  presidentPhoneNumber: string;
  webmasterName: string;
  webmasterPhoneNumber: string;
}

const router = useRouter();
const api = useAPI();
const { data: contactInfoResponse } = await useAsyncData("contact-info", () =>
  api.send(new GetStateCommand({ key: "contact-info" })),
);
const contactInfo: ContactInfo = JSON.parse(contactInfoResponse.value);
</script>
<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-item">
        <div class="footer-link">
          <strong
            @click="router.push({ path: '/privacy' })"
            class="cursor--clickable"
            >개인정보 처리방침</strong
          >
          <a href="mailto:admin@era1980.org">이메일 문의하기</a>
        </div>
        <p>
          Copyright ⓒ 2022-2025 동아대학교 전자기술학회 All rights reserved.
        </p>
      </div>
      <div class="footer-item footer-contact">
        <p>연락처</p>
        <div class="footer-contact-info">
          <RiPhoneLine class="footer-contact-icon" />
          <a
            :href="`tel:${contactInfo.presidentPhoneNumber}`"
            class="footer-contact-info--phone-number"
            >{{ contactInfo.presidentPhoneNumber }}</a
          >
          <span>{{ contactInfo.presidentName }}</span>
        </div>
        <div class="footer-contact-info">
          <RiPhoneLine class="footer-contact-icon" />
          <a
            :href="`tel:${contactInfo.webmasterPhoneNumber}`"
            class="footer-contact-info--phone-number"
            >{{ contactInfo.webmasterPhoneNumber }}</a
          >
          <span>{{ contactInfo.webmasterName }}</span>
        </div>
        <div class="footer-contact-info">
          <RiPhoneLine class="footer-contact-icon" />
          <a
            :href="`tel:010-2834-8242`"
            class="footer-contact-info--phone-number"
            >010-2834-8242</a
          >
          <span>개발자</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped lang="scss">
.footer {
  margin-top: 32px;
  color: $text-color-grey;
  font-size: $font-size-sm;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $text-color-grey;
  }
  .footer-item {
    width: 50%;
    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &-contact {
    &-icon {
      width: 20px;
      fill: #7d7d7d;
    }
    &-info {
      display: flex;
      align-items: center;
      flex-direction: row;
      &--phone-number {
        font-variant-numeric: tabular-nums;
        color: black;
        margin-right: 8px;
        margin-left: 8px;
        text-decoration: underline;
        color: black !important;
      }
    }
  }
  &-link > *:not(:first-child) {
    margin-left: 16px;
  }
  > .container {
    margin: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
  }
}
@include mobile {
  .footer {
    padding-top: 32px;
    padding-bottom: 16px;
    min-height: initial;
    .container {
      flex-direction: column;
      padding-left: 16px;
      padding-right: 16px;
    }
    .footer-item {
      width: 100%;
    }
    .footer-item:not(:nth-child(1)) {
      padding-top: 16px;
    }
  }
}
</style>
