import type { User } from "@internal/database";

import {
  ChangePasswordCommand,
  GetMeCommand,
  LogoutCommand,
  RefreshTokenCommand,
  SignInCommand,
} from "@internal/server-api";

export const useAccountStore = defineStore(
  "account",
  () => {
    const api = useAPI();

    const id = ref("");
    const rememberId = ref(false);
    const info = reactive<{
      displayName: null | string;
      generation: number;
      uid: null | string;
    }>({
      displayName: null,
      generation: -1,
      uid: null,
    });
    const me = ref<
      Partial<Pick<User, "generation" | "name" | "role" | "uniqueId">>
    >({});
    const loggedIn = useState("loggedIn", () => false);
    const clearAccountData = () => {
      accessToken.value = info.displayName = info.uid = null;
      info.generation = -1;
    };
    const accessToken = useState<null | string>("accessToken", () => null);
    watch(accessToken, (newValue) => {
      const token = newValue;
      api.accessToken = token;
      loggedIn.value = !!token && token.length > 0;
    });
    const fetch = async () => {
      accessToken.value = await api.send(
        new RefreshTokenCommand(),
        useRequestHeaders(["cookie"]),
      );
      const myInfo = await api.send(
        new GetMeCommand({ generation: true, name: true, role: true }),
      );
      me.value.name = myInfo.name;
      me.value.generation = myInfo.generation;
      me.value.role = myInfo.role;
      me.value.uniqueId = myInfo.uniqueId;
    };
    const login = async (id: string, password: string): Promise<void> => {
      const token = await api.send(new SignInCommand({ id, password }));
      accessToken.value = token.accessToken;
      await fetch();
    };
    const logout = async () => {
      await api.send(new LogoutCommand());
      clearAccountData();
      navigateTo("/account/login");
    };
    const changePassword = async (password: string, newPassword: string) => {
      await api.send(
        new ChangePasswordCommand({
          newPassword,
          password,
        }),
      );
    };
    return {
      accessToken,
      changePassword,
      fetch,
      id,
      info,
      loggedIn,
      login,
      logout,
      me,
      rememberId,
    };
  },
  { persist: { pick: ["id", "rememberId"] } },
);
