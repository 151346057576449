
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as mapZ94bEYnZTJMeta } from "/app/apps/web/src/pages/about/map.vue?macro=true";
import { default as officerta841nHNpcMeta } from "/app/apps/web/src/pages/about/officer.vue?macro=true";
import { default as indexuaTi28IIdtMeta } from "/app/apps/web/src/pages/account/[menu]/index.vue?macro=true";
import { default as find_45id5nekwt9Lv7Meta } from "/app/apps/web/src/pages/account/find-id.vue?macro=true";
import { default as loginqZfjKEwutUMeta } from "/app/apps/web/src/pages/account/login.vue?macro=true";
import { default as reset_45password0FFhsJPtSbMeta } from "/app/apps/web/src/pages/account/reset-password.vue?macro=true";
import { default as indexZp9TuvxS0nMeta } from "/app/apps/web/src/pages/board/[board_id]/index.vue?macro=true";
import { default as index5jthpkw0hNMeta } from "/app/apps/web/src/pages/board/[board_id]/post/[post_id]/index.vue?macro=true";
import { default as modifycnVBNYFUb8Meta } from "/app/apps/web/src/pages/board/[board_id]/post/[post_id]/modify.vue?macro=true";
import { default as writebDhutlArMDMeta } from "/app/apps/web/src/pages/board/[board_id]/write.vue?macro=true";
import { default as _91token_934TlhMLrZN8Meta } from "/app/apps/web/src/pages/email/verify/[token].vue?macro=true";
import { default as indexFrDg441YV4Meta } from "/app/apps/web/src/pages/index.vue?macro=true";
import { default as _91uid_93WPbIUQonPWMeta } from "/app/apps/web/src/pages/notification/[uid].vue?macro=true";
import { default as privacyBK7OrfsIN8Meta } from "/app/apps/web/src/pages/privacy.vue?macro=true";
import { default as agreeNcP82S87eZMeta } from "/app/apps/web/src/pages/register/agree.vue?macro=true";
import { default as sign_45upTJ5nDt0GbZMeta } from "/app/apps/web/src/pages/register/sign-up.vue?macro=true";
import { default as successrRJMnPK5yHMeta } from "/app/apps/web/src/pages/register/success.vue?macro=true";
export default [
  {
    name: "about-map",
    path: "/about/map",
    component: () => import("/app/apps/web/src/pages/about/map.vue")
  },
  {
    name: "about-officer",
    path: "/about/officer",
    component: () => import("/app/apps/web/src/pages/about/officer.vue")
  },
  {
    name: "account-menu",
    path: "/account/:menu()",
    meta: indexuaTi28IIdtMeta || {},
    component: () => import("/app/apps/web/src/pages/account/[menu]/index.vue")
  },
  {
    name: "account-find-id",
    path: "/account/find-id",
    meta: find_45id5nekwt9Lv7Meta || {},
    component: () => import("/app/apps/web/src/pages/account/find-id.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginqZfjKEwutUMeta || {},
    component: () => import("/app/apps/web/src/pages/account/login.vue")
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    meta: reset_45password0FFhsJPtSbMeta || {},
    component: () => import("/app/apps/web/src/pages/account/reset-password.vue")
  },
  {
    name: "board-board_id",
    path: "/board/:board_id()",
    component: () => import("/app/apps/web/src/pages/board/[board_id]/index.vue")
  },
  {
    name: "board-board_id-post-post_id",
    path: "/board/:board_id()/post/:post_id()",
    component: () => import("/app/apps/web/src/pages/board/[board_id]/post/[post_id]/index.vue")
  },
  {
    name: "board-board_id-post-post_id-modify",
    path: "/board/:board_id()/post/:post_id()/modify",
    component: () => import("/app/apps/web/src/pages/board/[board_id]/post/[post_id]/modify.vue")
  },
  {
    name: "board-board_id-write",
    path: "/board/:board_id()/write",
    component: () => import("/app/apps/web/src/pages/board/[board_id]/write.vue")
  },
  {
    name: "email-verify-token",
    path: "/email/verify/:token()",
    component: () => import("/app/apps/web/src/pages/email/verify/[token].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/web/src/pages/index.vue")
  },
  {
    name: "notification-uid",
    path: "/notification/:uid()",
    component: () => import("/app/apps/web/src/pages/notification/[uid].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/app/apps/web/src/pages/privacy.vue")
  },
  {
    name: "register-agree",
    path: "/register/agree",
    component: () => import("/app/apps/web/src/pages/register/agree.vue")
  },
  {
    name: "register-sign-up",
    path: "/register/sign-up",
    meta: sign_45upTJ5nDt0GbZMeta || {},
    component: () => import("/app/apps/web/src/pages/register/sign-up.vue")
  },
  {
    name: "register-success",
    path: "/register/success",
    meta: successrRJMnPK5yHMeta || {},
    component: () => import("/app/apps/web/src/pages/register/success.vue")
  }
]