<script setup lang="ts">
import { Button } from "@internal/ui";
import TossFaceAlert from "@tossface/u1F4E2.svg?component";
interface Props {
  endDate: null | string;
  link: null | string;
  startDate: null | string;
  text: string;
}
const props = withDefaults(defineProps<Props>(), {
  endDate: null,
  link: null,
  startDate: null,
});
const hide = useCookie("hide-alert", { maxAge: 60 * 60 * 24 * 7 });
const dayjs = useDayjs();
const show =
  props.endDate && props.startDate
    ? dayjs().isBetween(props.startDate, props.endDate, "d", "[]")
    : true;
const hideAlert = () => {
  hide.value = "y";
};
</script>

<template>
  <div class="w-alert" v-if="!hide && show">
    <div class="container">
      <div class="w-alert-content">
        <TossFaceAlert class="mr-1" style="width: 30px" />
        <a
          :href="link"
          class="w-alert-link w-alert-text text-overflow-hidden"
          v-if="link"
        >
          {{ props.text }}
        </a>
        <p class="w-alert-text text-overflow-hidden" v-else>
          {{ props.text }}
        </p>

        <Button
          @click="hideAlert"
          background-color="#ffb900"
          label="일주일간 안 보기"
          text-color="black"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.w-alert {
  width: 100%;
  height: 40px;
  background-color: #ffb900;
  color: black;
  .w-alert-link {
    text-decoration: underline;
  }
  .w-alert-link:visited,
  .w-alert-link:link {
    color: black;
  }
  .w-alert-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .w-alert-text {
    flex: 1;
    padding-right: 16px;
  }
  .container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
</style>
